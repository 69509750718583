<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "@/helpers/debounce";
// import moment from "moment";

export default {
    page: {
        title: "Konfigurasi MUK",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Kode MUK", name: "kode_muk" },
            { width: "auto", label: "Nama Skema", name: "skema_nama" },
            { width: "auto", label: "Metode Sertifikasi", name: "skema_nama" },
            { width: "auto", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Konfigurasi MUK",
            items: [
                {
                    text: "Konfigurasi",
                    href: "/konfig-muk",
                },
                {
                    text: "Daftar Konfigurasi MUK",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                per_page: 10,
                search: "",
                sort_field: 0,
                sort_order: "desc",
                filter_status: true, // untuk filtering data
                skema_sertifikasi_id: null,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            // variabel referensi
            optionsSkemaSertifikasi: [],

            // variabel page
            skema_sertifikasi_selected: "",
        };
    },

    created() {
        this.searchDatatable = debounce(() => {
            this.getDataTable();
            // call fetch API to get results
        }, 500);
    },
    mounted() {
        this.getDataTable();
        this.getSkemaSertifikasi();
    },
    methods: {
        getSkemaSertifikasi() {
            // load data
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-muk") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data;
                    console.log(response_data);
                    if (response_data.meta.code == 200) {
                        self.table_data = response_data_fix.data;
                        self.configPagination(response_data_fix);
                        self.currentTablePage = response_data_fix.current_page;
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        configPagination(data) {
            console.log(data);
            let self = this;
            self.pagination.lastPage = data?.last_page;
            self.pagination.currentPage = data?.current_page;
            self.pagination.total = data?.total;
            self.pagination.lastPageUrl = data?.last_page_url;
            self.pagination.nextPageUrl = data?.next_page_url;
            self.pagination.prevPageUrl = data?.prev_page_url;
            self.pagination.from = data?.from;
            self.pagination.to = data?.to;
            self.pagination.links = data?.links;
        },
        sortBy(key) {
            let self = this;
            self.sortKey = key;
            self.sortOrders[key] = self.sortOrders[key] * -1;
            self.tableData.column = self.getIndex(self.columns, "name", key);
            self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
            self.getDataTable();
        },
        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },

        changeStatusData(status, id) {
            let self = this;

            let text_alert = "";
            if (status == true) {
                text_alert = "Apakah Anda yakin untuk mengubah status?";
            } else {
                text_alert = "Apakah Anda yakin untuk mengubah status?";
            }

            Swal.fire({
                title: "Perhatian",
                text: text_alert,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "put",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-muk/" + id + "/status",
                        data: {
                            id: id,
                            status: status,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Data berhasil diperbarui.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.getDataTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
        deleteData(id) {
            let self = this;
            Swal.fire({
                title: "Perhatian",
                text: "Anda yakin ingin menghapus data tersebut?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "konfigurasi-muk/" + id,
                        data: {
                            id: id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Data berhasil dihapus.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.getDataTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
        toPage: function (url) {
            let self = this;
            self.getDataTable(url);
        },
        filterDatatable() {
            let self = this;
            self.tableData.skema_sertifikasi_id = self.skema_sertifikasi_selected?.id_skema_sertifikasi;
            self.getDataTable();
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col text-end">
                                <router-link :to="{ name: 'add-konfig_muk' }" class="btn btn-sm btn-primary m-1"><i class="fa fa-plus"></i> Tambah Konfigurasi Materi Uji Kompetensi</router-link>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <hr />
                            <div class="row">
                                <div class="form-group col-md-2">
                                    <label for="statusTable">Status</label>
                                    <select class="form-select" id="statusTable" v-model="tableData.filter_status">
                                        <option value="true">ENABLE</option>
                                        <option value="false">DISABLE</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="statusTable">Skema Sertifikasi</label>
                                    <v-select :options="optionsSkemaSertifikasi" label="nama_skema_sertifikasi" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"> </v-select>
                                </div>
                                <div class="col-md-1">
                                    <br />
                                    <div class="btn btn-primary btn-sm mt-2" v-on:click="filterDatatable"><i class="fa fa-filter"></i> Filter</div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.per_page" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                                    </div>
                                </div>
                                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                    <tbody>
                                        <tr v-if="loadingTable">
                                            <td colspan="6"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                        </tr>
                                        <tr v-else-if="table_data == ''">
                                            <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ key_data + 1 }}
                                            </td>
                                            <td>
                                                {{ row_data.kode_muk }}
                                            </td>
                                            <td>
                                                {{ row_data.skema_nama }}
                                            </td>
                                            <td>
                                                {{ row_data.metode_sertifikasi?.nama_metode_sertifikasi }}
                                            </td>
                                            <td class="text-center">
                                                <div v-if="row_data.status == true">
                                                    <div class="badge badge-pill badge-soft-success font-size-12">ENABLE</div>
                                                </div>
                                                <div v-else>
                                                    <div class="badge badge-pill badge-soft-danger font-size-12">DISABLE</div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div v-if="row_data.status == true">
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-danger btn-sm" v-on:click="changeStatusData(false, row_data.id)"><i class="fa fa-times"></i> Disable</button>
                                                        <router-link
                                                            :to="{
                                                                name: 'edit-konfig_muk',
                                                                params: { id: row_data.id },
                                                            }"
                                                            class="btn btn-info btn-sm"
                                                            ><i class="fa fa-edit"></i> Edit</router-link
                                                        >
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-warning btn-sm" v-on:click="changeStatusData(true, row_data.id)"><i class="fas fa-undo"></i> Restore</button>
                                                        <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.id)"><i class="fa fa-trash"></i> Hapus</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </datatable>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
